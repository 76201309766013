/* eslint-disable */
import { useEffect, useState } from "react";
import { SectionBack } from "../components/SectionBack";
import { PageLayout } from "../components/Layout";
import { TrackCard } from "../components/TrackCard";
import { useUpdateOrderStatusMutation } from "../services/slices/track";
import { useParams, useNavigate } from "react-router-dom";
import { TrackModal } from "../components/Modal";
const TrackDeliveryDetails = ({
  step,
  setStep,
  dataDetails,
  setDataDetails,
}: any) => {
  const [trackNumber, setTrackNumber] = useState("");
  const [showTrackModal, setShowTrackModal] = useState(false);
  const [click, setClick] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updateOrderStatus, { isLoading, data, isError }] =
    useUpdateOrderStatusMutation();
  const { orderNo } = useParams();
  const history = useNavigate();

  useEffect(() => {
    if (orderNo && step !== 1) {
      setTrackNumber(orderNo);
      fetchOrderData(orderNo);
    } else if (orderNo && step === 1) {
      setTrackNumber(orderNo);
    }
    setStep(null);
  }, [orderNo]);

  useEffect(() => {
    if (data && data?.status !== "Error") {
      setDataDetails(data);
      setLoading(false);
    } else if (data && data?.status !== "Error") {
      setDataDetails(data);
      setLoading(false);
    } else if (data && data?.status === "Error") {
      const timer = setTimeout(() => {
        setErrorMessage(data?.description);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [data]);

  useEffect(() => {
    if (isError && trackNumber !== "") {
      setLoading(false);
      setTrackNumber("");
      setTrackNumber("");
      const timer = setTimeout(() => {
        setErrorMessage("Network Error");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isError]);
  const fetchOrderData = async (orderNumber: string) => {
    setErrorMessage("");
    try {
      await updateOrderStatus({ orderId: orderNumber });
    } catch (error) {
      setErrorMessage("Error fetching order data");
    }
  };

  const handleTrackButtonClick = () => {
    history(`/track-delivery/${trackNumber}`);
    fetchOrderData(trackNumber);
  };

  const handleView = () => {
    const newWindow = window.open("/image-view/delivery-proof");
    if (newWindow) {
      newWindow.document.write(`
              <img src="${dataDetails?.order?.proofOfDelivery}" 
              top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" 
              ></img>
          `);
      newWindow.focus();
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = dataDetails?.order?.proofOfDelivery;
    link.download = "downloaded_file";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageLayout isLoading={isLoading && click}>
      <div className="mb-32">
        <SectionBack
          onClick={() => history("/track-delivery")}
          imgStr="/images/bike-rope.svg"
          content="Track your shipment"
        />
        <div className="flex bg-white lg:flex-row flex-col items-start lg:space-y-0 space-y-8 justify-center px-4 md:px-20 py-10 lg:space-x-10 xl:space-x-32 lg:justify-between w-full">
          <div className="mt-10 lg:max-w-[40%] w-full">
            <TrackCard
              value={trackNumber}
              onTrackDetails={handleTrackButtonClick}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setClick(false);
                setTrackNumber(e.target.value);
              }}
              isLoading={isLoading}
            />
          </div>
          {errorMessage !== "" && (
            <div className="bg-[#f19797] text-center  border-[red]  border w-full px-5 py-2 rounded-md">
              <p className="text-white items-center  text-[14px] text-center  font-onestMedium">
                {errorMessage}
              </p>
            </div>
          )}
          {dataDetails && errorMessage === "" && (
            <div className="animate_animated animate_bounceIn w-full min-w-1/2 h-fit border-[2px] border-[#E7EFE6] rounded-[12px] bg-white">
              <div className="p-4">
                <p className="text-xl font-onestBold text-textColor">
                  Your shipment status
                </p>
                <div className="flex md:flex-row flex-col items-start md:items-center gap-8 md:gap-16 mt-8">
                  <div>
                    <p className="text-[#90978F] leading-10 text-[16px] font-onestBold">
                      TRACKING NUMBER
                    </p>
                    <p className="text-textColor font-onestBold text-[18px]">
                      {dataDetails?.order?.orderNo}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#90978F] leading-10  font-onestBold text-[16px] ">
                      SENDER
                    </p>
                    <p className="text-textColor font-onestBold text-[18px]">
                      {dataDetails?.order?.senderName}
                    </p>
                  </div>
                </div>
                <p className="text-[#90978F]  font-onestBold my-8">
                  TRACKING TIMELINE
                </p>
                {dataDetails?.order?.orderStatus.toLowerCase() ===
                  "pending pick-up" && (
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="w-full relative mb-1">
                        <hr className="bg-[#E5ECE4] rounded-r-lg relative h-[6px] w-full" />
                        <div className="bg-black h-3 w-3 rounded-sm absolute -top-1 left-0" />
                      </div>
                    </div>
                    <div className="flex mt-2 justify-between ">
                      <div className="">
                        <img src="/images/house.svg" alt="" />
                        <p className="font-onestMedium mt-4">Pending pickup</p>
                      </div>

                      <div className="">
                        <div className="flex justify-end">
                          <img src="/images/house.svg" alt="" />
                        </div>
                        <p className="font-onestMedium mt-4">
                          Dropoff location
                        </p>

                        <div className=" flex justify-end">
                          <p className="text-[#787E83] font-onestRegular text-sm">
                            {dataDetails?.order?.recipientAddress}
                          </p>
                        </div>
                      </div>
                    </div>

                    <hr className="bg-[#BFE0BA] my-8  h-[2px] w-full" />

                    <div>
                      <p className="text-[#90978F] text-[16px] font-onestBold tracking-[3px]	 ">
                        DELIVERY STATUS
                      </p>
                      <p className="font-onestBold text-[18px] my-2 text-[#FFA800]">
                        {dataDetails?.order?.orderStatus}
                      </p>
                    </div>
                  </div>
                )}
                {dataDetails?.order?.orderStatus.toLowerCase() !==
                  "pending pick-up" &&
                  dataDetails?.order?.orderStatus.toLowerCase() !==
                    "delivered" &&
                  dataDetails?.order?.orderStatus.toLowerCase() !==
                    "returned" &&
                  dataDetails?.order?.orderStatus.toLowerCase() !==
                    "pending payment" && (
                    <div>
                      <div className="flex group justify-between items-center">
                        <hr className="bg-lightGreen rounded-l-lg h-[6px] w-full" />
                        <div
                          className="flex items-center justify-between px-1 rounded-xl w-[30rem] group-hover:bg-lightGreen border border-lightGreen cursor-pointer"
                          onClick={() => setShowTrackModal(!showTrackModal)}
                        >
                          <div className="bg-lightGreen  group-hover:bg-white   h-[10px] w-[10px] rounded-full" />
                          <p className="text-sm text-lightGreen group-hover:text-white ">
                            Out for Delivery
                          </p>
                          <img
                            src="/images/arrow.svg"
                            className="pr-3 h-2"
                            alt=""
                          />
                        </div>

                        <div className="w-full relative">
                          <hr className="bg-[#E5ECE4] rounded-r-lg relative h-[6px] w-full" />
                          <div className="bg-black h-3 w-3 rounded-sm absolute -top-1 right-0" />
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between ">
                        <div className="">
                          <img src="/images/house.svg" alt="" />
                        </div>
                        <div className="text-center ">
                          <p className="w-44 text-[12px]  -mt-2 text-[#00243D] mx-auto">
                            {dataDetails?.history?.[0]?.statusDescription}
                          </p>
                        </div>

                        <div className="">
                          <div className="flex justify-end">
                            <img src="/images/house.svg" alt="" />
                          </div>

                          <div className=" flex justify-end">
                            <div className="text-end">
                              <p className="font-onestMedium mt-4">
                                Dropoff location
                              </p>
                              <p className="text-[#787E83] font-onestRegular text-sm">
                                {dataDetails?.order?.recipientAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="bg-[#BFE0BA] my-8  h-[2px] w-full" />

                      <div>
                        <p className="text-[#90978F] text-[16px] font-onestBold tracking-[3px]	 ">
                          DELIVERY STATUS
                        </p>
                        <p className="font-onestBold text-[18px] my-2 text-[#FFA800]">
                          {dataDetails?.order?.orderStatus}
                        </p>
                      </div>
                    </div>
                  )}
                {dataDetails?.order?.orderStatus.toLowerCase() ===
                  "returned" && (
                  <div>
                    <div className="flex group justify-between items-center">
                      <hr className="bg-[#FF463A]  rounded-l-lg h-[6px] w-full" />
                      <div
                        className="flex items-center justify-between px-1 rounded-xl w-[30rem] group-hover:bg-[#FF463A]  group-hover:border-white border border-[#FF463A] cursor-pointer"
                        onClick={() => setShowTrackModal(!showTrackModal)}
                      >
                        <div className="bg-[#FF463A] group-hover:bg-white h-[10px] w-[10px] rounded-full" />
                        <p className="text-md text-[#FF463A] group-hover:text-white">
                          Delivery Failed
                        </p>
                        <img
                          src="/images/arrow.svg"
                          className="pr-3 h-2.5"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex mt-2 justify-between ">
                      <div className="">
                        <img src="/images/house.svg" alt="" />
                      </div>

                      <div className="">
                        <div className="flex space-x-1 items-center">
                          <p className="w-44 text-[12px]  text-left -mt-2 text-[#00243D] mx-auto">
                            {dataDetails?.history?.[0]?.statusDescription}
                          </p>
                          <div className="flex ">
                            <img src="/images/house.svg" alt="" />
                          </div>
                        </div>

                        <div className="flex  justify-end">
                          <div className="flex flex-col justify-end">
                            <p className="font-onestMedium mt-4">
                              Dropoff location
                            </p>
                            <p className="text-[#787E83] font-onestRegular text-sm">
                              {dataDetails?.order?.recipientAddress}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="bg-[#BFE0BA] my-8  h-[2px] w-full" />

                    <div>
                      <p className="text-[#90978F] text-[16px] font-onestBold tracking-[3px]	 ">
                        DELIVERY STATUS
                      </p>
                      <p className="font-onestBold text-[18px] my-2 text-[#FFA800]">
                        {dataDetails?.order?.orderStatus}
                      </p>
                    </div>
                  </div>
                )}
                {dataDetails?.order?.orderStatus.toLowerCase() ===
                  "delivered" && (
                  <div>
                    <div className="flex group justify-between items-center">
                      <hr className="bg-[#45C734] rounded-l-lg h-[6px] w-full" />
                      <div
                        className="flex items-center justify-between px-1 rounded-xl w-[30rem]  border group-hover:bg-white bg-[#45C734] cursor-pointer"
                        onClick={() => setShowTrackModal(!showTrackModal)}
                      >
                        <div className="bg-[#fff] group-hover:bg-[#45C734] h-[10px] w-[10px] rounded-full" />
                        <p className="text-sm text-white group-hover:text-[#45C734]">
                          Delivery Successful
                        </p>
                        <img
                          src="/images/arrow.svg"
                          className="pr-3 h-2.5"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex mt-2 justify-between ">
                      <div className="">
                        <img src="/images/house.svg" alt="" />
                      </div>

                      <div className="">
                        <div className="flex space-x-2">
                          <p className="w-44 text-[12px]  -mt-2 text-[#129D09] mx-auto">
                            {dataDetails?.history?.[0]?.statusDescription}
                          </p>
                          <img src="/images/house.svg" alt="" />
                        </div>

                        <div className=" flex justify-end">
                          <div className="text-end">
                            <p className="font-onestMedium mt-4">
                              Dropoff location
                            </p>
                            <p className="text-[#787E83] font-onestRegular text-sm">
                              {dataDetails?.order?.recipientAddress}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="bg-[#BFE0BA] my-8  h-[2px] w-full" />

                    <div>
                      <p className="text-[#90978F] text-[16px] font-onestBold tracking-[3px]	 ">
                        DELIVERY STATUS
                      </p>
                      <p className="font-onestBold text-[12px] md:text-[18px] my-2 text-[#FFA800]">
                        {dataDetails?.order?.orderStatus}
                      </p>
                    </div>
                    {dataDetails?.order?.proofOfDelivery && (
                      <div className="pt-5">
                        <p className="text-[#90978F] text-[16px] font-onestBold tracking-[3px]	 ">
                          PROOF OF DELIVERY
                        </p>
                        <div className="flex items-center mt-1 space-x-3">
                          <button
                            onClick={handleDownload}
                            className="btn btn-download border rounded px-4 py-2"
                            disabled={!dataDetails?.order?.proofOfDelivery}
                          >
                            Download
                          </button>
                          <button
                            onClick={handleView}
                            className="btn btn-view hover:underline"
                            disabled={!dataDetails?.order?.proofOfDelivery}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full justify-center pt-16">
          <img src="/images/Banner 2.svg" alt="banner-icon" />
        </div>
        {showTrackModal && (
          <TrackModal
            data={dataDetails?.history}
            onClick={() => {
              setShowTrackModal(false);
            }}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default TrackDeliveryDetails;
